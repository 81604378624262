<template>
  <div class="pollinglog public-box">
    <el-form :inline="true">
      <el-form-item>
        <MyInput v-model="formInline.worker_name" placeholder="请输入被投诉人">
          <template slot="pre">被投诉人:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="date"
          @change="timeChange"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <MyButton title="搜索" @click="currentChange(1)" class="offset">
          <template slot="preImage">
            <img src="../../../assets/img/icon/search.png" alt="" />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton
          @click="pendingExport"
          :accessId="2947"
          class="offset"
          title="导出"
        >
          <template slot="preImage">
            <img src="../../../unit/img/down.png" alt="" />
          </template>
        </MyButton>
      </el-form-item>
    </el-form>
    <div class="public-table">
      <el-table
        :data="tableData"
        v-loading="loading"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="被投诉人" prop="worker_name"></el-table-column>
        <el-table-column
          label="投诉问题"
          prop="complaint_reason"
        ></el-table-column>
        <el-table-column label="投诉时间" prop="create_at"></el-table-column>
        <el-table-column label="投诉人" prop="contact_name"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <pop tips="详情" :accessId="8187" @myclick="details(scope.row)">
              <img
                class="icon"
                src="../../../assets/img/icon/xiangqing.png"
                alt
              />
            </pop>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="public-page">
      <el-pagination
        next-text="下一页"
        prev-text="上一页"
        layout="total, prev, pager, next"
        :total="total"
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        @current-change="currentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "pending",
  data() {
    return {
      formInline: {
        pageNum: 1,
        pageSize: 10,
        complaint_status: 0,
      },
      total: 0,
      tableData: [],
      date: "",
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    pendingExport() {
      this.$exported(
        "/market_complaint/export",
        this.formInline,
        "投诉待处理.xlsx"
      );
    },
    timeChange(date) {
      if (date) {
        this.formInline.beginTime = date[0];
        this.formInline.endTime = date[1];
      } else {
        this.formInline.beginTime = "";
        this.formInline.endTime = "";
      }
    },
    getList() {
      this.loading = true;
      this.tableData = [];
      this.$request
        .HttpPost("/market_complaint/list", this.formInline)
        .then((res) => {
          this.loading = false;
          this.total = res.data.total;
          this.tableData = res.data.list;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    currentChange(page) {
      this.formInline.pageNum = page;
      this.getList();
    },
    details(item) {
      // this.$router.push({
      //   path: `/complain/complainlist/detail?id=${item.complaint_id}`
      // })
      this.$openNewTab(`/complain/complainlist/detail?id=${item.complaint_id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.pollinglog {
  .line {
    text-align: center;
  }
}
</style>
